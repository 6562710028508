<template>
  <div id="signly-video" @click="onVideoClick" @mouseover="showControls = true" @mouseleave="showControls = false">
    <SignlyVideoLoader
        :loading="loading"
        :video-width="videoWidth"
    />

    <SignlyVideoControls
        :title="$t('video.clickToPlayVideo')"
        :aria-label="`${playing ? $t('video.clickToPauseVideo') : $t('video.clickToPlayVideo')}`"
        v-show="!loading"
        :playing="playing"
        :show-controls="showControls"
        :video-width="videoWidth"
        @playVideo="onPlayButtonClick"
    />

    <video
        ref="player"
        playsinline
        autoplay
        muted
        :style="{ width: `${videoWidth}px !important` }"
        :poster="poster"
        :src="videoUrl"
        :class="videoClass"
        :aria-label="$t('video.title')"
    />
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import SignlyVideoControls from '@/components/SignlyVideo/SignlyVideoControls'
import SignlyVideoLoader from '@/components/SignlyVideo/SignlyVideoLoader'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlyVideo',
  mixins: [video],
  components: {
    SignlyVideoControls,
    SignlyVideoLoader
  },
  props: {
    videoWidth: {
      type: Number,
      default: Defaults.video.INITIAL_WIDTH
    },
    videoUrl: {
      type: String,
      default: null
    },
    playing: {
      type: Boolean,
      default: true
    },
    showContainer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      videoClass: '',
      showControls: false,
      showControlsTimeOut: null
    }
  },
  mounted () {
    this.player = this.$refs.player
    this.player.addEventListener('loadstart', () => {
      this.loading = true
      this.videoClass = 'loading'
      this.showControls = false
    })
    this.player.addEventListener('loadeddata', () => {
      this.loading = false
      this.playVideo()
    })
    this.player.addEventListener('progress', () => {
      this.$emit('update:playing', true)
    })
    this.player.addEventListener('waiting', () => {
      this.loading = true
      this.videoClass = 'loading'
    })
    this.player.addEventListener('ended', () => {
      this.$emit('update:playing', false)
      this.$emit('videoStopped')
      this.showControls = true
    })
    this.player.addEventListener('pause', () => {
      this.$emit('update:playing', false)
      this.showControls = true
    })
    this.player.addEventListener('playing', () => {
      this.$emit('update:playing', true)
      this.loading = false
      this.videoClass = 'playing'
      this.showControls = false
    })
  },
  beforeUnmount () {
    this.player.removeEventListener('loadstart', () => {
      this.loading = true
      this.videoClass = 'loading'
      this.showControls = false
    })
    this.player.removeEventListener('loadeddata', () => {
      this.loading = false
      this.playVideo()
    })
    this.player.removeEventListener('progress', () => {
      this.$emit('update:playing', true)
    })
    this.player.removeEventListener('waiting', () => {
      this.loading = true
      this.videoClass = 'loading'
    })
    this.player.removeEventListener('ended', () => {
      this.$emit('update:playing', false)
      this.$emit('videoStopped')
      this.showControls = true
    })
    this.player.removeEventListener('pause', () => {
      this.$emit('update:playing', false)
      this.showControls = true
    })
    this.player.removeEventListener('playing', () => {
      this.$emit('update:playing', true)
      this.loading = false
      this.videoClass = 'playing'
      this.showControls = false
    })
  },
  computed: {
    poster () {
      return Defaults.video.poster
    }
  },
  watch: {
    videoWidth () {
      this.$emit('videoResize', this.player.width)
    },
    loading (newValue) {
      newValue ? this.videoClass = 'loading' : this.videoClass = 'playing'
    },
    videoUrl (newValue) {
      if (newValue) {
        this.$emit('update:playing', true)
      }
    },
    showContainer (newValue) {
      if (!newValue) {
        this.$emit('update:playing', false)
        this.player.pause()
      }
    }
  },
  methods: {
    playVideo () {
      if (this.videoUrl && window.URL) {
        window.URL.revokeObjectURL(this.videoUrl)
      }

      this.$emit('videoResize', this.player.width)
    },
    onPlayButtonClick (playing) {
      this.$emit('update:playing', playing)
    },
    onVideoClick () {
      if (!this.player) {
        return
      }

      if (this.player.paused) {
        this.player.play()
        this.updateControls()
      } else {
        this.player.pause()
      }
    },
    updateControls () {
      this.showControls = true
      clearTimeout(this.showControlsTimeOut)
      this.showControlsTimeOut = setTimeout(() => {
        this.showControls = false
      }, 500)
    }
  }
}
</script>

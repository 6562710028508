<template>
  <div id="signly-header" :style="{ 'min-height': headerHeight, top: -headerHeight }">
    <div class="signly-header-logo" :style="{ 'max-width': logoWidth }">
      <a :href="signlyWebsite" target="_blank" :aria-label="$t('header.title')" :title="$t('header.title')">
        <img
            :src="logoSrc"
            alt="Signly logo"
            :style="{ 'max-height': logoHeight }"
        >
      </a>
    </div>

    <div class="signly-header-actions">
      <div>
        <button
            ref="shrinkButton"
            :title="`${isMinVideoWidth ? $t('header.shrinkWindowDisabled') : $t('header.shrinkWindow')}`"
            :class="shrinkButtonClass"
            :style="buttonStyle"
            :aria-label="`${isMinVideoWidth ? $t('header.shrinkWindowDisabled') : $t('header.shrinkWindow')}`"
            @click="onShrinkWindow"
        >
          <fa
              icon="minus"
              :size="iconSize"
              class="signly-header-icon"
          />
        </button>
      </div>

      <div>
        <button
            ref="expandButton"
            :title="`${isMinVideoWidth ? $t('header.expandWindowDisabled') : $t('header.expandWindow')}`"
            :class="expandButtonClass"
            :style="buttonStyle"
            :aria-label="`${isMinVideoWidth ? $t('header.expandWindowDisabled') : $t('header.expandWindow')}`"
            @click="onExpandWindow"
        >
          <fa
              icon="plus"
              :size="iconSize"
              class="signly-header-icon"
          />
        </button>
      </div>

      <div>
        <button
            ref="closeButton"
            :title="$t('header.hideWindow')"
            :class="closeButtonClass"
            :style="buttonStyle"
            :aria-label="$t('header.hideWindow')"
            @click="onCloseContainer"
        >
          <fa
              icon="times"
              :size="iconSize"
              class="signly-header-icon"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlyHeader',
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultTooltip: {
        content: '',
        trigger: 'manual',
        show: false
      }
    }
  },
  computed: {
    logoSrc () {
      return Defaults.images.baseUrl + 'signly-word-white.svg'
    },
    signlyWebsite () {
      return Defaults.website.baseUrl
    },
    headerHeight () {
      return Defaults.header.height[this.videoSize]
    },
    iconSize () {
      if (this.videoSize === 'xs') {
        return null
      }
      return Defaults.header.iconSize[this.videoSize]
    },
    logoHeight () {
      return Defaults.header.logoHeight[this.videoSize]
    },
    logoWidth () {
      return Defaults.header.logoWidth[this.videoSize]
    },
    buttonStyle () {
      return {
        width: Defaults.header.buttonSize[this.videoSize],
        height: Defaults.header.buttonSize[this.videoSize]
      }
    },
    isMaxVideoWidth () {
      return this.videoWidth >= Defaults.video.MAX_WIDTH
    },
    isMinVideoWidth () {
      return this.videoWidth <= Defaults.video.MIN_WIDTH
    },
    shrinkButtonClass () {
      return [
        'signly-header-button',
        this.isMinVideoWidth ? 'disabled' : null
      ]
    },
    expandButtonClass () {
      return [
        'signly-header-button',
        'middle-button',
        this.isMaxVideoWidth ? 'disabled' : null
      ]
    },
    closeButtonClass () {
      return [
        'signly-header-button'
      ]
    }
  },
  methods: {
    onShrinkWindow () {
      this.$nextTick(() => {
        this.$refs.shrinkButton.focus()
      })

      if (this.videoWidth - Defaults.video.WIDTH_INCR >= Defaults.video.MIN_WIDTH) {
        this.$emit('update:videoWidth', this.videoWidth - Defaults.video.WIDTH_INCR)
      }
    },
    onExpandWindow () {
      this.$nextTick(() => {
        this.$refs.expandButton.focus()
      })

      if (this.videoWidth + Defaults.video.WIDTH_INCR <= Defaults.video.MAX_WIDTH) {
        this.$emit('update:videoWidth', this.videoWidth + Defaults.video.WIDTH_INCR)
      }
    },
    onCloseContainer () {
      this.$nextTick(() => {
        this.$refs.closeButton.focus()
      })

      this.$emit('closeWindow')
    }
  }
}
</script>
